import React, { useState, useEffect } from 'react';
import PlayerDetailsModal from '../components/modals/PlayerDetailsModal';
import { calculatePositionData } from '../data';
import axios from 'axios';
import AdminDashboard from '../admin/AdminDashboard';
import MatchCard from '../components/MatchCard';
import PlayerCard from '../components/PlayerCard';
import LiveIndicator from '../components/LiveIndicator';
import LeagueTable from '../components/LeagueTable'
import { useDispatch, useSelector } from 'react-redux';
import { gameActions } from '../store/game'; // Import your game actions
import KnockoutStage from '../components/KnockoutStage';


const HomePage = ({ isAdminLoggedIn }) => {

    const dispatch = useDispatch();
    const [selectedTab, setSelectedTab] = useState('table');
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const [isLive, setIsLive] = useState(false);
    const [games, setGames] = useState([]);
    const [teams, setTeams] = useState([]);
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        axios.get('/players.json')
            .then(response => {
                setPlayers(response.data);
            })
            .catch(error => {
                console.error('Error fetching player data', error)
            });
        axios.get('/teams.json')
            .then(response => {
                console.log('teams data returned was:', response.data);
                setTeams(calculatePositionData(response.data).sort((a, b) => a.points < b.points));
            })
            .catch(error => {
                console.error('Error fetching teams data');
            })
        axios.get('/match.json')
            .then(response => {
                setGames(response.data)
            })
            .catch(error => {
                console.error('Error fetching match data', error)
            });
        // Fetch players data from the server when the component mounts
        axios.get('/api/players')
            .then(response => {
                // Dispatch an action to update the players array in Redux store
                dispatch(gameActions.setPlayers(response.data));
            })
            .catch(error => {
                console.error('Error fetching players:', error);
            });
    }, [dispatch]);

    const handlePlayerClick = (player) => {
        setSelectedPlayer(player);
    };

    const handleClosePopup = () => {
        setSelectedPlayer(null);
    };

    return (
        <>
            <h1 className='bg-[#101052] text-white p-5 font-bold'>RTM Tournament</h1>
            {/* NAVBAR */}
            <nav className="bg-[#101052] text-white tabs">
                <div className={`tab ${selectedTab === 'home' ? 'active' : ''}`} onClick={() => setSelectedTab('home')}>
                    Home
                </div>
                <div className={`tab ${selectedTab === 'matches' ? 'active' : ''}`} onClick={() => setSelectedTab('matches')}>
                    Games
                </div>
                <div className={`tab ${selectedTab === 'table' ? 'active' : ''}`} onClick={() => setSelectedTab('table')}>
                    Table
                </div>
                <div className={`tab ${selectedTab === 'players' ? 'active' : ''}`} onClick={() => setSelectedTab('players')}>
                    Players
                </div>
                {isAdminLoggedIn && (
                    <div className={`tab ${selectedTab === 'admin' ? 'active' : ''}`} onClick={() => setSelectedTab('admin')}>
                        Admin
                    </div>
                )}
            </nav>
            {/* Tabbed content */}
            <div className="homepage-container p-5 h-full">
                {selectedTab === 'home' && (
                    <div className='max-w-screen-lg mx-auto'>
                        <div className="flex justify-center items-center">
                            <div className="w-1/2 flex justify-center">
                                <img src="raj.jpeg" alt="Left Image" className="w-auto h-[360px]" />
                            </div>
                            <div className="w-1/2 flex justify-center">
                                <img src="samiya.jpeg" alt="Right Image" className="w-auto h-[360px]" />
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <p>Welcome to our Badminton Tournament in Sylhet, Bangladesh! Players will compete for top honors and prizes for 1st, 2nd, and 3rd place. Stay tuned to our website for live score updates and follow the action as it unfolds on the courts. Let the games begin!</p>
                        </div>
                    </div>
                )}
                {selectedTab === 'matches' && (
                    <div className="px-[20px] max-w-screen-lg mx-auto">
                        <h2 className="text-xl font-bold mb-4">Games</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                            {games.map((match, index) => (
                                <MatchCard index={index} match={match} teamsData={teams} />
                            ))}
                        </div>
                    </div>
                )}
                {selectedTab === 'table' && (
                    <div className="px-[10px]">
                        {isLive && <LiveIndicator />}
                        {/* TODO: Place a knockout stage UI here with a semi final and final */}
                        <h2 className="text-2xl font-bold mb-5">Knockout Stage</h2>
                        <KnockoutStage />
                        <div className='mt-5 font-bold'>Point Table</div>
                        <LeagueTable teamsData={teams} />
                    </div>
                )}
                {selectedTab === 'players' && (
                    <div className="px-[20px] max-w-screen-lg mx-auto">
                        <h2 className="text-xl font-bold mb-4">Players</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {players.map((player) => (
                                <PlayerCard key={player._id} player={player} handlePlayerClick={handlePlayerClick} teamsData={teams} />
                            ))}
                        </div>
                    </div>
                )}
                {selectedTab === 'admin' && isAdminLoggedIn && (
                    <AdminDashboard />
                )}
                {selectedPlayer && (
                    <PlayerDetailsModal player={selectedPlayer} onClose={handleClosePopup} />
                )}
            </div>
        </>
    );
};

export default HomePage;
