import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { gameActions } from '../store/game'; // Import your game actions

const AdminDashboard = () => {
    const players = useSelector((state) => state.game.players);
    const teams = useSelector((state) => state.game.teams);
    const isLive = useSelector((state) => state.game.isLive);
    const dispatch = useDispatch();
    const [newPlayerName, setNewPlayerName] = useState('');
    const [newTeamName, setNewTeamName] = useState('');

    const [selectedData, setSelectedData] = useState({});
    const [selectedPlayerId, setSelectedPlayerId] = useState('');
    //const [selectedTeamId, setSelectedTeamId] = useState('');

    const handleAddPlayer = () => {
        const token = localStorage.getItem('adminToken');
        const newPlayer = { name: newPlayerName, picture: newPlayerName + '.png' };
        axios.post('/api/players', newPlayer, { headers: { Authorization: `${token}` } })
            .then(response => {
                // refresh page
                setNewPlayerName('');
            })
            .catch(error => {
                console.error('Error adding player:', error);
            });
    };

    const handleDeletePlayer = (playerId) => {
        const token = localStorage.getItem('adminToken');
        axios.delete(`/api/players/${playerId}`, { headers: { Authorization: `${token}` } })
            .then(() => {
                dispatch(gameActions.deletePlayerById(playerId))
            })
            .catch(error => {
                console.error('Error deleting player:', error);
            });
    };

    const handleToggleIsLive = () => {
        const newIsLive = !isLive;
        /*
        dispatch(gameActions.updateIsLive(newIsLive));

        const token = localStorage.getItem('adminToken');
        // Make an API call to update the isLive status
        axios.post('/api/isLive', { isLive: newIsLive }, { headers: { Authorization: `${token}` } })
            .then(response => {
                console.log('isLive status updated successfully:', response.data);
            })
            .catch(error => {
                console.error('Failed to update isLive status:', error);
                // Optionally, handle the error
            });*/
    };

    const handleAddTeam = () => {
        console.log('Add team was requested');
        const token = localStorage.getItem('adminToken');
        const newTeam = { name: newTeamName };
        axios.post('/api/teams', newTeam, { headers: { Authorization: `${token}` } })
            .then(response => {
                console.log('response from server was...', response.data);
                dispatch(gameActions.addTeam(response.data)); // Add team to Redux state
                setNewTeamName('');
            })
            .catch(error => {
                console.error('Error adding team:', error);
            });
    };

    const handleDeleteTeam = (teamId) => {
        console.log('Handle delete team pressed');
        const token = localStorage.getItem('adminToken');
        axios.delete(`/api/teams/${teamId}`, { headers: { Authorization: `${token}` } })
            .then(() => {
                console.log('deleted team on server..');
                dispatch(gameActions.deleteTeamById(teamId));
            })
            .catch(error => {
                console.error('Error deleting team:', error);
            });
    };

    const handleAddPlayerToTeam = () => {
        if (!selectedPlayerId) {
            console.error('Selected team or player is invalid.');
            return;
        }

        const { team, id } = selectedPlayerId; // Extract team and player id from selectedPlayerId

        const token = localStorage.getItem('adminToken');
        // Make a POST request to add the player to the team
        axios.post(`/api/teams/player`, { teamId: team._id, playerId: id }, { headers: { Authorization: `${token}` } })
            .then(response => {
                // Assuming response.data contains the updated team object
                // Update the team in Redux state with the new player
                dispatch(gameActions.addPlayerToTeam({ teamId: team._id, playerId: id }));
                // Optionally, you can fetch updated team data from the server and update Redux state
            })
            .catch(error => {
                console.error('Error adding player to team:', error);
            });
    };


    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl font-bold mb-4">Admin Dashboard</h1>
            <div className="mb-4">
                <h2 className="text-xl font-semibold mb-2">Add New Player</h2>
                <div className="flex">
                    <input
                        type="text"
                        value={newPlayerName}
                        onChange={(e) => setNewPlayerName(e.target.value)}
                        className="border border-gray-300 p-2 mr-2"
                        placeholder="Enter player name"
                    />
                    <button
                        onClick={handleAddPlayer}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    >
                        Add Player
                    </button>
                </div>
            </div>
            <div>
                <h2 className="text-xl font-semibold mb-2">Player List</h2>
                <ul>
                    {players.map(player => (
                        <li key={player._id} className="flex items-center justify-between border-b border-gray-300 py-2">
                            <span>{player.name}</span>
                            <button
                                onClick={() => handleDeletePlayer(player._id)}
                                className="text-red-500 hover:text-red-700"
                            >
                                Delete
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="mt-4">
                <h2 className="text-xl font-semibold mb-2">Live Status</h2>
                <div className="flex items-center">
                    <label className="mr-2">Live:</label>
                    <input
                        type="checkbox"
                        checked={isLive}
                        onChange={handleToggleIsLive}
                    />
                </div>
            </div>

            <div className="mb-4">
                <h2 className="text-xl font-semibold mb-2">Add New Team</h2>
                <div className="flex">
                    <input
                        type="text"
                        value={newTeamName}
                        onChange={(e) => setNewTeamName(e.target.value)}
                        className="border border-gray-300 p-2 mr-2"
                        placeholder="Enter team name"
                    />
                    <button
                        onClick={handleAddTeam}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md"
                    >
                        Add Team
                    </button>
                </div>
            </div>
            <div>
                <h2 className="text-xl font-semibold mb-2">Team List</h2>
                <ul>
                    {teams.map(team => (
                        <li key={team._id} className="flex flex-col border-b border-gray-300 py-2">
                            <div className="flex items-center justify-between mb-2">
                                <span className="font-semibold">{team.name}</span>
                                <div className="flex items-center">
                                    {/* Add Player Dropdown */}
                                    <select
                                        value={selectedData}
                                        onChange={(e) => { setSelectedPlayerId({ team: team, id: e.target.value }); setSelectedData(e.target.value) }}
                                        className="border border-gray-300 p-2 mr-2"
                                    >
                                        <option value="">Select Player</option>
                                        {players.map(player => (
                                            <option key={player._id} value={player._id}>{player.name}</option>
                                        ))}
                                    </select>
                                    <button
                                        onClick={() => handleAddPlayerToTeam(team._id)}
                                        className="bg-green-500 text-white px-4 py-2 rounded-md mr-2"
                                    >
                                        Add Player
                                    </button>
                                    <button
                                        onClick={() => handleDeleteTeam(team._id)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                            {/* Display Players on the Team */}
                            <div>
                                <span className="text-sm">Players on this Team:</span>
                                <ul>
                                    {team.players.map(playerId => {
                                        const player = players.find(player => player._id === playerId);
                                        return player ? <li key={player._id}>{player.name}</li> : null;
                                    })}
                                </ul>
                            </div>
                        </li>
                    ))}

                </ul>
            </div>
        </div>
    );
};

export default AdminDashboard;


