const PlayerCard = ({ player, handlePlayerClick, teamsData }) => {

    const teamName = teamsData.find((team) => team.players.some((p) => p.id === player.id))?.name;

    return (
        <div
            key={player.id}
            className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition duration-300 hover:shadow-lg"
            onClick={() => handlePlayerClick(player)}
        >
            <img
                src={player.picture}
                alt={player.name}
                className="w-full h-48 object-cover"
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = 'profile.png';
                }}
            />
            <div className="p-4">
                <h3 className="text-lg font-semibold">{player.name}</h3>
                <p className="text-gray-600">{teamName ? teamName : null}</p>
            </div>
        </div>
    )
}

export default PlayerCard;