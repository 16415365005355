// data.js
/*
export const playerData = [
    { id: 1, name: 'Rumel', picture: 'rumel.png' },
    { id: 2, name: 'Sunna', picture: 'sunna.png' },
    { id: 3, name: 'Maruf', picture: 'maruf.png' },
    { id: 4, name: 'Samsuddin', picture: 'samsuddin.png' },
    { id: 5, name: 'Saeid', picture: 'saeid.png' },
    { id: 6, name: 'Sabbir', picture: 'sabbir.png' },
    { id: 7, name: 'Saif', picture: 'saif.png' },
    { id: 8, name: 'Juned', picture: 'juned.png' },
    { id: 9, name: 'Manna', picture: 'manna.png' },
    { id: 10, name: 'Mahid', picture: 'mahid.png' },
    { id: 11, name: 'Kamran', picture: 'kamran.png' },
    { id: 12, name: 'Habib', picture: 'habib.png' },
    { id: 13, name: 'Rajab', picture: 'rajab.jpeg' },
    { id: 14, name: 'Shahan', picture: 'shahan.png' },
    { id: 15, name: 'Ahad', picture: 'ahad.png' },
    { id: 16, name: 'Saju', picture: 'saju.png' },
    { id: 17, name: "Arif", picture: "arif.png" }
];

export const teamsData = [
    {
        id: 1,
        name: 'Team 1',
        players: [playerData[0], playerData[15]],
        wins: 3,
        losses: 1,
        points: 10,
        position: 0
    },
    {
        id: 2,
        name: 'Team 2',
        players: [playerData[2], playerData[1]],
        wins: 2,
        losses: 1,
        points: 7,
        position: 0
    },
    {
        id: 3,
        name: 'Team 3',
        players: [playerData[5], playerData[9]],
        wins: 1,
        losses: 2,
        points: 5,
        position: 0
    },
    {
        id: 4,
        name: 'Team 4',
        players: [playerData[8], playerData[16]],
        wins: 2,
        losses: 2,
        points: 8,
        position: 0
    },
    {
        id: 5,
        name: 'Team 5',
        players: [playerData[3], playerData[7]],
        wins: 1,
        losses: 3,
        points: 6,
        position: 0
    },
];

export const calculateMatches = (teamsData) => {
    /*
    let matchData = [];
    let matchId = 1;

    // Iterate through each team
    for (let i = 0; i < teamsData.length; i++) {
        const homeTeam = teamsData[i];

        // Iterate through remaining teams to generate matches
        for (let j = i + 1; j < teamsData.length; j++) {
            const awayTeam = teamsData[j];

            // Generate match objects for home and away games
            const homeMatch = {
                id: matchId++,
                name: '',
                teamA: homeTeam.id,
                teamB: awayTeam.id,
            };

            const awayMatch = {
                id: matchId++,
                name: '',
                teamA: awayTeam.id,
                teamB: homeTeam.id,
            };

            // Add matches to matchData
            matchData.push(homeMatch);
            matchData.push(awayMatch);
        }
    }

    console.log(JSON.stringify(matchData));
    return matchData;

    let matchData = [
        { "id": 1, "name": "", "teamA": 1, "teamB": 2, winner: 2 },
        { "id": 2, "name": "", "teamA": 3, "teamB": 4, winner: 4 },
        { "id": 3, "name": "", "teamA": 5, "teamB": 1, winner: 1 },
        { "id": 4, "name": "", "teamA": 2, "teamB": 4, winner: 4 },
        { "id": 5, "name": "", "teamA": 3, "teamB": 5, winner: 3 },
        { "id": 6, "name": "", "teamA": 1, "teamB": 4, winner: 1 },
        { "id": 7, "name": "", "teamA": 2, "teamB": 5, winner: 2 },
        { "id": 8, "name": "", "teamA": 3, "teamB": 1, winner: 1 },
        { "id": 9, "name": "", "teamA": 4, "teamB": 5, winner: 5 },
        { "id": 10, "name": "", "teamA": 2, "teamB": 3 },
        { "id": 11, "name": "", "teamA": 4, "teamB": 1 },
        { "id": 12, "name": "", "teamA": 2, "teamB": 1 },
        { "id": 13, "name": "", "teamA": 1, "teamB": 5 },
        { "id": 14, "name": "", "teamA": 1, "teamB": 3 },
        { "id": 15, "name": "", "teamA": 4, "teamB": 2 },
        { "id": 16, "name": "", "teamA": 5, "teamB": 4 },
        { "id": 17, "name": "", "teamA": 4, "teamB": 3 },
        { "id": 18, "name": "", "teamA": 5, "teamB": 2 },
        { "id": 19, "name": "", "teamA": 3, "teamB": 2 },
        { "id": 20, "name": "", "teamA": 5, "teamB": 3 },
    ];

    return matchData;
}*/


export const calculatePositionData = (teamsData) => {
    // Sort teams by points in descending order
    const sortedTeams = teamsData.slice().sort((a, b) => b.points - a.points);

    let currentPosition = 1;
    let currentPoints = sortedTeams[0].points;

    // Assign positions based on points
    for (let i = 0; i < sortedTeams.length; i++) {
        if (sortedTeams[i].points === currentPoints) {
            sortedTeams[i].position = currentPosition;
        } else {
            currentPoints = sortedTeams[i].points;
            currentPosition++;
            sortedTeams[i].position = currentPosition;
        }
    }

    // Set position to 0 for teams with 0 points
    for (let i = 0; i < sortedTeams.length; i++) {
        if (sortedTeams[i].points === 0) {
            sortedTeams[i].position = 0;
        }
    }

    return sortedTeams;
};

export const gamesData = [

];
