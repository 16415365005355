import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoggedIn: false,
    isLive: true,
    players: [],
    teams: [],
};

const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        updateIsLive: (state, action) => {
            state.isLive = action.payload;
        },
        // Update the logged in variable, to either true or false
        updateLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload;
        },
        setPlayers: (state, action) => {
            state.players = action.payload;
        },
        // Add a way to add a new player object {id: 1, name: 'test', picture: 'test.png'}
        addPlayer: (state, action) => {
            const newPlayer = action.payload;
            // Check if the player ID already exists
            if (state.players.some(player => player.id === newPlayer.id)) {
                console.error("Player ID already exists.");
                return;
            }
            state.players.push(newPlayer);
        },

        // Add a way to delete a player object by id
        deletePlayerById: (state, action) => {
            state.players = state.players.filter(player => player.id !== action.payload);
        },

        // Add a way to update a player object by id
        updatePlayerById: (state, action) => {
            const updatedPlayer = action.payload;
            // Check if the player exists
            const existingPlayer = state.players.find(player => player.id === updatedPlayer.id);
            if (!existingPlayer) {
                console.error("Player not found.");
                return;
            }
            const index = state.players.findIndex(player => player.id === updatedPlayer.id);
            state.players[index] = updatedPlayer;
        },

        // Add a way to create a team {id: 1, name: 'Team 1', players: []}
        addTeam: (state, action) => {
            const newTeam = action.payload;
            // Check if the team ID already exists
            if (state.teams.some(team => team._id === newTeam._id)) {
                console.error("Team ID already exists.");
                return;
            }
            state.teams.push(newTeam);
        },

        deleteTeamById: (state, action) => {
            const teamIdToDelete = action.payload;
            // Filter out the team with the provided ID
            state.teams = state.teams.filter(team => team._id !== teamIdToDelete);
        },

        // Add a way to add a player to a team, you need the team id and the player id then it adds to the players array
        addPlayerToTeam: (state, action) => {
            const { teamId, playerId } = action.payload;
            // Check if the team exists
            const team = state.teams.find(team => team._id === teamId);
            if (!team) {
                console.error("Team not found.");
                return;
            }
            // Check if the player already exists in the team
            if (team.players.includes(playerId)) {
                console.error("Player already exists in the team.");
                return;
            }
            team.players.push(playerId);
        },

        // Add a way to remove a player from a team, you need the team id and player id, then remove from the players array
        removePlayerFromTeam: (state, action) => {
            const { teamId, playerId } = action.payload;
            const team = state.teams.find(team => team._id === teamId);
            if (team) {
                team.players = team.players.filter(id => id !== playerId);
            }
        },
    },
});

export const gameActions = gameSlice.actions;

export default gameSlice.reducer;