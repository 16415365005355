// PlayerDetailsModal.js
import React from 'react';
import './PlayerDetailsModal.css';

const PlayerDetailsModal = ({ player, onClose }) => {
  return (
    <div className="player-details-overlay">
      <div className="player-details-modal">
        <div className="player-details">
          <h2>{player.name}</h2>
          <p>ID: {player.id}</p>
          <p>Additional details...</p>
          <img src={player.picture} alt={player.name} onError={(e) => { e.target.onerror = null; e.target.src = 'profile.png'; }} />
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default PlayerDetailsModal;
