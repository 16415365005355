import { configureStore } from "@reduxjs/toolkit";
import gameReducer from './game';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, gameReducer)

export const store = configureStore({
  reducer: { game: persistedReducer },
})

export const persistor = persistStore(store)