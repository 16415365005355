import './App.css';
import React, { useState, useEffect } from 'react';
import HomePage from './pages/HomePage';
import AdminLoginModal from './components/modals/AdminLoginModal';
import Footer from './components/NavBar/Footer';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { gameActions } from './store/game';

const App = () => {

  const [adminModalOpen, setAdminModalOpen] = useState(false);
  const isAdminLoggedIn = useSelector(state => state.game.isLoggedIn);
  const dispatch = useDispatch();

  const handleAdminLogin = (username, password) => {
    // Make API call to authenticate admin user
    axios.post('/api/users/login', { username, password })
      .then(response => {
        const { token } = response.data;
        // Store token in local storage
        localStorage.setItem('adminToken', token);
        // Set admin login status to true
        dispatch(gameActions.updateLoggedIn(true));
        // Close the admin login modal
        setAdminModalOpen(false);
      })
      .catch(error => {
        console.error('Login failed:', error);
        // Handle login failure (display error message, etc.)
      });
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    // Perform logout actions here
    dispatch(gameActions.updateLoggedIn(false));
  };

  return (
    <div className="App">
      <HomePage isAdminLoggedIn={isAdminLoggedIn} />
      <Footer
        isAdminLoggedIn={isAdminLoggedIn}
        setAdminModalOpen={() => setAdminModalOpen(true)}
        handleLogout={handleLogout}
      />
      {adminModalOpen && <AdminLoginModal onLogin={handleAdminLogin} onClose={() => setAdminModalOpen(false)} />}
    </div>
  );
};

export default App;
