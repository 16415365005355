import { useState, useEffect } from 'react';

const LeagueTable = ({ teamsData }) => {
    const [sortedTeamsData, setSortedTeamsData] = useState([]);

    useEffect(() => {
        // Copy the teamsData array to avoid mutating the original data
        const sortedData = [...teamsData];

        // Sort the teams based on points in descending order
        sortedData.sort((a, b) => b.points - a.points);

        // Update the sortedTeamsData state
        setSortedTeamsData(sortedData);
    }, [teamsData]);

    const calculatePoints = (wins, losses) => {
        return wins * 3 + losses * 1;
    };

    const calculateMatches = (wins, losses) => {
        return wins + losses;
    }

    const renderName = (name) => {
        return name.length > 6 ? name.slice(0, 6) + ".." : name;
    };

    return (
        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Team</th>
                    <th>MP</th>
                    <th>W</th>
                    <th>L</th>
                    <th>Pts</th>
                </tr>
            </thead>
            <tbody>
                {sortedTeamsData.map((team, index) => (
                    <tr key={team.id} className={team.points > 0 ? team.position === 1 ? "bg-yellow-200" : team.position === 2 ? 'bg-grey' : team.position === 3 ? 'bg-brown' : '' : ''}>
                        <td style={{ position: 'relative', paddingLeft: '10px' }}>
                            {index === 0 && <div style={{ backgroundColor: '#FFD700', width: '10px', height: '100%', position: 'absolute', left: 0, top: 0 }}></div>}
                            {index === 1 && <div style={{ backgroundColor: '#C0C0C0', width: '10px', height: '100%', position: 'absolute', left: 0, top: 0 }}></div>}
                            {index === 2 && <div style={{ backgroundColor: '#CD7F32', width: '10px', height: '100%', position: 'absolute', left: 0, top: 0 }}></div>}
                            {team.position}
                        </td>
                        <td className="relative flex flex-col items-center">
                            <div className="font-bold text-center mb-5 ">
                                {team.name}
                            </div>

                            <div className="flex justify-between w-full">
                                <div className="flex flex-col items-center">
                                    <img
                                        className='w-[50px] h-[50px] mr-[5px] rounded-[50%]'
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = 'profile.png';
                                        }}
                                        src={team.players[0].picture}
                                        alt={team.players[0].name}
                                    />
                                    <div className="text-center" title={team.players[0].name}>
                                        {renderName(team.players[0].name)}
                                    </div>
                                </div>

                                <div className="flex flex-col items-center">
                                    <img
                                        className='w-[50px] h-[50px] mr-[5px] rounded-[50%]'
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = 'profile.png';
                                        }}
                                        src={team.players[1].picture}
                                        alt={team.players[1].name}
                                    />
                                    <div className="text-center" title={team.players[1].name}>
                                        {renderName(team.players[1].name)}
                                    </div>
                                </div>
                            </div>
                        </td>


                        <td>{calculateMatches(team.wins, team.losses)}</td>
                        <td>{team.wins}</td>
                        <td>{team.losses}</td>
                        <td className="font-bold">{calculatePoints(team.wins, team.losses)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default LeagueTable;