import React from 'react';

const TeamPlay = ({ team, player1, player2, color }) => {
    return (
        <div className={`teams ${color} flex flex-col gap-2 rounded-lg p-5 flex-grow`}>
            <div className="relative flex flex-col items-center">
                <div className="font-bold text-center mb-5 ">
                    {team}
                </div>

                <div className="flex flex-col md:flex-row items-center justify-center w-full">
                    <div className="flex flex-col items-center mb-2 md:mr-8">
                        <img
                            className='w-[50px] h-[50px] mr-[5px] rounded-[50%]'
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'profile.png';
                            }}
                            src={`${player1.toLowerCase()}.png`}
                        />
                        <div className="text-center">
                            {player1}
                        </div>
                    </div>

                    <div className="flex flex-col items-center">
                        <img
                            className='w-[50px] h-[50px] mr-[5px] rounded-[50%]'
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = 'profile.png';
                            }}
                            src={`${player2.toLowerCase()}.png`}
                        />
                        <div className="text-center">
                            {player2}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const KnockoutStage = () => {
    return (
        <div className="knockout-stage-container flex flex-col md:flex-row gap-4 items-center justify-center">
            <div className="semi-final flex bg-gray-100 p-4 rounded-lg flex-col items-center gap-8 w-[300px]">
                <h3 className="text-lg font-bold mb-2">Semi Final</h3>
                <div className="teams flex flex-col gap-2 w-[250px]">
                    <TeamPlay team='Team 1' player1='Rumel' player2='Saju' color='bg-yellow-200' />
                    <div className="vs text-xl font-bold">vs</div>
                    <TeamPlay team='Team 4' player1='Manna' player2='Arif' color='bg-brown' />
                </div>
            </div>
            <svg width="100" height="50" viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
                <line x1="0" y1="25" x2="50" y2="25" stroke="black" strokeWidth="2" />
                <polygon points="50,20 70,25 50,30" fill="black" />
            </svg>
            <div className="final flex bg-gray-100 p-4 rounded-lg flex-col items-center gap-8 w-[300px]">
                <h3 className="text-lg font-bold mb-2">Final</h3>
                <div className="teams flex flex-col gap-2 w-[250px]">
                    <TeamPlay team='Team 1' player1='Rumel' player2='Saju' color='bg-yellow-200' />
                    <div className="vs text-xl font-bold">vs</div>
                    <TeamPlay team='Team 2' player1='Maruf' player2='Sunna' color='bg-grey' />
                </div>
            </div>
            <svg width="100" height="50" viewBox="0 0 100 50" xmlns="http://www.w3.org/2000/svg">
                <line x1="0" y1="25" x2="50" y2="25" stroke="black" strokeWidth="2" />
                <polygon points="50,20 70,25 50,30" fill="black" />
            </svg>
            <div className="final flex bg-green-100 p-4 rounded-lg flex-col items-center gap-8 w-[300px] border-4 border-green-600">
                <h3 className="text-lg font-bold mb-2 text-green-800">Champions</h3>
                <div className="teams flex flex-col gap-2 rounded-lg p-5 md:w-[250px]">
                    <TeamPlay team='Team 1' player1='Rumel' player2='Saju' color='bg-yellow-200' />
                </div>
            </div>
        </div>
    );
};

export default KnockoutStage;
