const MatchCard = ({ index, match, teamsData }) => {
    return (
        <div key={index} className="bg-gray-200 rounded-lg shadow-md overflow-hidden min-w-min">
            <div className="flex justify-between items-center mb-2 p-4">
                <div className="font-bold">Game: {index + 1}</div>
                <div className="italic">{match.time}</div>
            </div>
            <div className="p-4">
                <span className="font-bold">{teamsData.find(team => team.id === match.teamA).name}</span>
                <span className="m-2">vs</span>
                <span className="font-bold">{teamsData.find(team => team.id === match.teamB).name}</span>
            </div>
            <div className="italic p-4">W: {teamsData.find(team => team.id === match.winner)?.name}</div>
        </div>
    )
}

export default MatchCard;