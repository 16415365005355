import React from 'react';

const Footer = ({ isAdminLoggedIn, setAdminModalOpen, handleLogout }) => {

    console.log('isAdminLoggedIn?', isAdminLoggedIn)
    return (
        <div className="flex justify-end p-2.5">
            {isAdminLoggedIn ? (
                <a className="no-underline ml-5 hover:underline" href="#" onClick={handleLogout}>Log out</a>
            ) : (
                <a className="no-underline ml-5 hover:underline" href="#" onClick={setAdminModalOpen}>Admin</a>
            )}
        </div>
    );
};

export default Footer;
